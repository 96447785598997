const EnumTipoPergunta = Object.freeze([
    {
        value: 'ME',
        label: 'Múltipla Escolha'
    },
    {
        value: 'DI',
        label: 'Dissertativa'
    },
    {
        value: 'IN',
        label: 'Inteiro'
    },
    {
        value: 'CB',
        label: 'Combo'
    }
]);

export default EnumTipoPergunta;
