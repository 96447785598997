<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/perguntas-sesmt/new"
        editPath="/perguntas-sesmt/"
        :expander="false"
        subtitle="Pergunta SESMT"
        :actionItems="actionItems"
        @onToggleMenu="onToggleMenu"
        tooltip="Cadastro Pergunta SESMT"
        permission="gestaosesmt_cadastros_questionario_sesmt_perguntas"
        :tipo="tipoFiltroEnum.SISTEMA"
        :modulo="moduloFiltroEnum.PERGUNTA_SESMT"
        descricao="FILTRO PADRÃO PERGUNTAS SESMT"
        nomeTelaDoManual="perguntas-sesmt-list"
        origemHistoricoAcao="/pergunta-sesmt"
    >
        <template #columns>
            <Column field="id" header="Cód." :sortable="true" sortField="pergunta_sesmt.id"></Column>
            <Column field="descricao" header="Pergunta" :sortable="true" sortField="pergunta_sesmt.descricao"></Column>
            <Column field="tipo" header="Tipo" :sortable="true" sortField="pergunta_sesmt.tipo">
                <template #body="slotProps">
                    {{ tipos.find((tipo) => tipo.value === slotProps.data.tipo)?.label }}
                </template>
            </Column>
            <Column field="opcoes" header="Opções">
                <template #body="slotProps">
                    {{
                        slotProps.data.opcoes &&
                        slotProps.data.opcoes
                            .map((opcao, index) => {
                                return ` ${index + 1}- ${opcao}`;
                            })
                            .toString()
                            .replace(',', ';')
                    }}
                </template>
            </Column>
            <Column field="createdAt" :sortable="true" sortField="pergunta_sesmt.createdAt" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps.data?.userCreated?.name }} </small>
                </template>
            </Column>

            <Column field="updatedAt" :sortable="true" sortField="pergunta_sesmt.updatedAt" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data?.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps.data?.userUpdated?.name }} </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
    <AppDeleteDialog
        v-if="showDeleteRecordDialog"
        v-model:visible="showDeleteRecordDialog"
        :record="record"
        @onConfirm="onClickDeleteRecord"
        @onClose="onClickCloseDeleteRecord"
    >
    </AppDeleteDialog>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import ModuloFiltroEnum from '../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../enums/TipoFiltroEnum';
import EnumTipoPergunta from '../../enums/EnumTipoPergunta';

export default {
    data() {
        return {
            service: null,
            record: {},
            showDeleteRecordDialog: false,
            tenantCustomers: [],
            tipos: EnumTipoPergunta,
            permission: 'gestaosesmt_cadastros_questionario_sesmt_perguntas',
            actionItems: [
                {
                    label: 'Alterar',
                    disabled: () => !this.$checkPermission(this.permission + ':editar') && this.permission,
                    icon: 'pi pi-pencil',
                    command: () => this.$refs.grid.editRecord(this.record)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    disabled: () => !this.$checkPermission(this.permission + ':excluir') && this.permission,
                    command: () => this.confirmDeleteRecord()
                }
            ]
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        },
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        },
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new SesmtService('/pergunta-sesmt');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        onToggleMenu(event, data) {
            this.record = data;
        },
        async onClickDeleteRecord(record) {
            if (!record) {
                return;
            }

            try {
                await this.service.remove(record.id);
                this.load();
                this.showDeleteRecordDialog = false;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Registro excluído com sucesso!',
                    life: 3000
                });
                await this.$emit('onAfterDelete');
            } catch (err) {
                const message = err?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao excluir registro! ' + message,
                    life: 5000
                });
            }
        },
        onClickCloseDeleteRecord() {
            this.showDeleteRecordDialog = false;
        },
        openDeleteRecordDialog() {
            this.showDeleteRecordDialog = true;
        },
        confirmDeleteRecord() {
            this.openDeleteRecordDialog();
        }
    }
};
</script>
